<template>
  <div class="result" v-if="selected_bot">

    <v-overlay :value="!botOption" style="z-index:10;">{{$t('global_loading_data')}}</v-overlay>
    
    <h2 class="mt-10 mb-0 text-center">
      <span class="half_line">{{$t('view_backtest_title')}}</span>
    </h2>
    <div v-if="false">

{{botOption}}
    </div>

    <div
      class="mb-5 mt-5 text--disabled text-center"
      style="font-size: 14px; font-weight: normal"
    >
      <div style="max-width: 600px" class="mx-auto mt-5 mb-5" v-if="botOption">
        <v-row class="mb-0 pb-0">
          <v-col cols="8" class="pa-0">
            <v-select
              :label="$t('view_backtest_select_strategy')"

              v-model="selected_bot"
              :items="botList"
              item-value="id"
              item-text="id"
              required
              outlined
              dense
              class="mb-2"
              hide-details=""
            ></v-select>
          </v-col>
          <v-col cols="4" 
              class="ma-0 pa-0">
              
  <v-sheet class="pb-0 pt-2 text-center">

            <v-switch class="ma-0 pa-0 ml-5" style="font-size:10px !important;;"
              v-model="backtestSave"
              :label="$t('view_backtest_save_options')"
            ></v-switch>
  </v-sheet>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-dialog
              ref="dialog1"
              v-model="modal"
              :return-value.sync="date"
              
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('view_backtest_date_start')"
                  prepend-icon="mdi-calendar-start"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                scrollable
                class="custom-picker"
                :weekday-format="getDay"
                :month-format="getMonth"
                :title-date-format="getMonth"
                :locale="$i18n.locale"
                no-title
                :allowedDates="allowedDates"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  {{$t('view_backtest_bt_cancel')}}
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog1.save(date)">
                  {{$t('view_backtest_bt_select')}}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="date2"
              
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  :label="$t('view_backtest_date_end')"
                  prepend-icon="mdi-calendar-end"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                scrollable
                class="custom-picker"
                :weekday-format="getDay"
                :month-format="getMonth"
                :title-date-format="getMonth"
                :locale="$i18n.locale"
                :allowedDates="allowedDates"
                no-title
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  {{$t('view_backtest_bt_cancel')}}
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog2.save(date2)">
                  {{$t('view_backtest_bt_select')}}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="4" class="pr-0 pt-0">




    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        :disabled="botOption.catch.pattern_length==0"
          v-bind="attrs"
          v-on="on"
              block
              color="primary"
              class="mt-4"
              @click="btStartTest"
            >
              {{$t('view_backtest_bt_start')}}
            </v-btn>

      </template>
      <span style="font-size:12px;" v-html="$t('view_backtest_bt_start_popup')">
        </span>
    </v-tooltip>



            



          </v-col>
        </v-row>
        <p>
          
              <small v-html="$t('view_backtest_description')">
              </small>
        </p>
        <p v-if="botOption.catch.pattern_length==0">
          
              <small style="color:red;" v-html="$t('view_backtest_pattern_ignore_msg')"></small>
        </p>
      </div>
    </div>


<v-dialog
      v-model="loading "
      persistent
      width="300"
    >
      <v-card
      class="pt-5"
      >
        <v-card-text style="text-align:center;">
          

<strong style="display:block;padding:10px;color:#000;font-size:18px;color:red;" v-if="testing">{{$t('view_backtest_bt_testing')}}</strong>
 <strong style="display:block;padding:10px;color:#000;font-size:18px;" v-if="!testing">{{$t('view_backtest_bt_data_loading')}}</strong>


             <p> <small v-html="$t('view_backtest_bt_start_popup')">
              </small>
             </p>

          <strong>{{$moment(date).format("yyyy-MM-DD")}}~
          {{$moment(date2).format("yyyy-MM-DD")}}</strong>
          <v-progress-linear
            indeterminate
            class="mt-3 mb-0"
          ></v-progress-linear>
        </v-card-text>


      <div style="text-align:center;padding:0 0 20px;" >
      <v-btn
      class="mx-auto"
        outlined
        rounded
        small 
        color="grey"
        @click="stopTest"
      >
        {{$t('view_backtest_bt_abort')}}
      </v-btn></div>

      </v-card>
    </v-dialog>




    <v-responsive v-if="dataOHLCV">
      <v-divider></v-divider>

    
      <v-responsive style="width: 880px" class="mx-auto">
        <h2 class=" mt-6 mb-0 text-center">
          {{$t('view_backtest_result_table_title')}}</h2>
      </v-responsive>

      <v-row style="width: 900px" class="mx-auto ">
        <v-col class="">
          <v-card class="mx-auto ma-5 pa-0" elevation="0">
            <v-card-text class="">
              <v-row dense no-gutters>
                <v-col 
                v-if="false"
                cols="12"
                style="text-align: center"
                  >
                  
              <small style="font-size:16px;">
                {{$t('view_backtest_result_subtitle',
                {
                  profit_count: profitCount,
                  loss_count: lossCount,
                  win_rate: $setFloat(profitCount/(lossCount+profitCount)*100,2),
                })}}
                

                {{waitCount}}
              </small>


                </v-col>




                
                <v-col
                cols="4" class="text-center">
                  <h3 class="text-center mb-3">{{$t('view_backtest_result_profit_rate')}}</h3>
                    <v-progress-circular
                      rotate="360"
                      size="100"
                      width="15"
                      :value="$setFloat(profitCount/(lossCount+profitCount)*100,2)"
                      color="red lighten-1
"
                    ><strong>
                    {{$setFloat(profitCount/(lossCount+profitCount)*100,2)}}%</strong>
                    </v-progress-circular>
                    <div class="mt-3">
                      {{$t('view_backtest_result_profit_text')}} {{profitCount}}, {{$t('view_backtest_result_loss_text')}} {{lossCount}}
                    </div>

                </v-col>
                
                <v-col
                cols="4" class="text-center">
                  <h3 class="text-center mb-3">{{$t('view_backtest_result_profit_total')}}</h3>
                  <div
                    class="text--primary text-h2 mt-7"
                    style="font-weight: bold;text-align:center;"
                  >
                    {{$setFloat(resultPercent,2)}}%
                  </div>
                </v-col>

                <v-col
                cols="4" class="text-center">
                  <h3 class="text-center mb-3">{{$t('view_backtest_result_avg_timelength')}}</h3>
                    <div>

                    <v-progress-circular
                      rotate="360"
                      size="100"
                      width="15"
                      :value="$setFloat((orders.length-waitCount)/orders.length*100,2)"
                      color="teal"
                    >
                      {{$setFloat(avgTime,1)}} {{$t('view_backtest_result_timelenth_hours')}}
                    </v-progress-circular>

                    </div>
                    <div class="mt-3">
                      {{$t('view_backtest_result_timelenth_close')}} {{orders.length-waitCount}}, {{$t('view_backtest_result_timelenth_wait')}} {{waitCount}}
                    </div>
                </v-col>


                <v-col 
                cols="12"
                style="text-align: center"
                  >
                  
              <small>
               {{$t('view_backtest_result_msg')}}<br>
              
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <a
          v-bind="attrs"
          v-on="on"
        >
          {{$t('view_backtest_result_splippage')}}
        </a>
      </template>
      <span style="font-size:12px;">
          {{$t('view_backtest_result_splippage_popup')}}</span>
    </v-tooltip>
          {{$t('view_backtest_result_splippage_msg')}}</small>


                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>



    <v-data-table
      :search="search"
      dense
      :headers="headers"
      :items="orders"
      item-key="id"
      class="elevation-1 mx-15 mb-10"
      :items-per-page="999999999999"

      hide-default-footer

    >
      <template v-slot:item.profit="{ item }">
        <v-chip
          x-small
          :color="getColor(item.profit)"
          text
          dark
          v-if="item.profit"
        >
          {{item.profit>0?'+':''}}{{ $setFloat(item.profit, 2) }}
        </v-chip>
      </template>
      <template v-slot:item.timestamp="{ item }">
        <small v-if="item.timestamp && $i18n.locale=='ko'">
          {{ $moment(item.timestamp).format("yyyy-MM-DD HH:mm:ss") }}
        </small>
        <small v-if="item.timestamp && $i18n.locale!='ko'">
          {{ $moment(item.timestamp).utc(0).format("yyyy-MM-DD HH:mm:ss") }}
        </small>
      </template>
      <template v-slot:item.closestamp="{ item }">
        <small v-if="item.closestamp&& $i18n.locale=='ko'">
          {{ $moment(item.closestamp).format("yyyy-MM-DD HH:mm:ss") }}
        </small>
        <small v-if="item.closestamp&& $i18n.locale!='ko'">
          {{ $moment(item.closestamp).utc(0).format("yyyy-MM-DD HH:mm:ss") }}
        </small>
      </template>
      <template v-slot:item.open="{ item }">
        <div v-if="item.open">
          {{ $addComma($setFloat(item.open, 4)) }}
        </div>
      </template>
      <template v-slot:item.close="{ item }">
        <div v-if="item.close">
          {{ $addComma($setFloat(item.close, 4)) }}
        </div>
      </template>
      <template v-slot:item.timelength="{ item }">
        <small v-if="item.closestamp && item.timestamp">
          {{item.timelength}} {{$t('view_backtest_result_timelenth_hours')}}
        </small>
      </template>
    </v-data-table>





    </v-responsive>
  </div>
</template>
  
  <script>
//import Vue from "vue";
//import HelloWorld from "../components/HelloWorld.vue";

//import _ from "lodash"

export default {
  name: "Result",

  components: {
    //HelloWorld,
  },
  data: (vm) => ({
    //BTC/USD 2018-11-14~
    //BTCUSDT 2020-03-25~

    backtestSave : false,
    backtestOption : {
          selected_bot: null,
          date: null,
          date2: null,
          },

    modal: false,
    modal2: false,

    search: "",

    
    date: vm.$moment().subtract(7, "days").format("yyyy-MM-DD"),
    //date: vm.$moment().subtract(1, "month").format("yyyy-MM-DD"),
    date2: vm.$moment().subtract(1, 'days').format("yyyy-MM-DD"),
    //date: vm.$moment().subtract(4, "month").format("yyyy-MM-DD"),
    //date2: vm.$moment().subtract(3, 'month').format("yyyy-MM-DD"),



    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,



    orders: [
      /*
        {
          id: 0,
          name: "BTC",
          position: "buy",
          amount: 9723,
          open: 34975,
          close: 34990,
          profit: "3",
          balance: 1.1,
          leverage: 1,
          timestamp: 0,
        },
        {
          id: 1,
          name: "BTC2",
          position: "sell",
          amount: 9723,
          open: 34990,
          close: 34970,
          profit: "3",
          balance: 1.1,
          leverage: 1,
          timestamp: 0,
        },
        {
          id: 2,
          name: "BTC",
          position: "sell",
          amount: 9723,
          open: 34990,
          close: 34970,
          profit: "3",
          balance: 1.1,
          leverage: 1,
          timestamp: 0,
        },
        */
    ],
    headers: [
      {
        text: "Symbol",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Side", value: "position" },
      //{ text: "진입수량", value: "amount" },
      { text: "Open", value: "open" },
      { text: "Close", value: "close" },
      { text: "Profit/Loss(%)", value: "profit" },
      //{ text: "잔고", value: "balance" },
      //{ text: "레버리지", value: "leverage" },
      { text: "Start (UTC+0)", value: "timestamp" ,width:120},
      { text: "End (UTC+0)", value: "closestamp" ,width:120 },
      { text: "Time range", value: "timelength" ,},
    ],

    dataOHLCV: null,
    nowOHLCV:null,

    resultPercent : 0,
    lossCount: 0,
    profitCount: 0,
    waitCount: 0,
    avgTime:0,
    
    loading:false,
    testing:false,


    //연속 3번 내려가거나 오르면
    //마지막 close값에서 매매 합니다
    //5분봉 기준에서
    //롱봇 가정
    //연속봉 패턴은 추세 상승시 좋음
    //확인후 진입 패턴은 역추세에 유리
    //봉확인후 진입은 양봉나올때까지 기다렸다가 진입

    selected_bot: 'long',
    bots:[
              {
                  id : 'long',
                  name : '롱봇 사용자 전략',
                  symbol: 'BTC/USDT', //코인 종류
                  side: 'buy', //방향 buy/sell
                  catch:{
                      timeframe:'5m',
                      count: 5, //추적할 지난 캔들범위, 향후 rsi, ma를 분석하기위해 남겨둠.
                      pattern : '---+', //캔들범위에서의 가장 최근패턴
                            candle_min: '',
                            candle_max: '',
                            candle_last_min:'',
                            candle_last_max:'',
                            
                            entrybase: 'exchange',
                          ordertype: 'market',
                            fundingtype: 'balance',
                            ma5:'',
                            ma10:'',
                            ma20:'',
                            ma30:'',
                    }, 
                  
                  limit:{ //물린 오더 제한
                    total: 0, //총 0 <- 제한없음
                    time: 0, // 재주문 딜레이 : 기준은 분(minutes)
                  },

                  profit: 0.5, //익절 기준 %
                  profit_position: 2, //포지션 기반 익절 기준 %
                        profit_time: '', //포지션 기반 익절 기준 %

                        profittype : 'percent',
                        profit_amount: 100,

                  stoploss: -2, //손절 기준 %
                  stoploss_position: -2, //포지션 기반 손절 기준 %

                  amount: 100, //매수금액
                  amounttype: 'normal', //매수금액 타입
                  synctype: false, //비중조절 타입
                  active:false,
            },
              {
                  id : 'short',
                  name : '숏봇 사용자 전략',
                  symbol: 'BTC/USDT', //코인 종류
                  side: 'sell', //방향 buy/sell
                  catch:{
                      timeframe:'5m',
                      count: 5, //추적할 지난 캔들범위
                      pattern : '+++-', //캔들범위에서의 가장 최근패턴
                            candle_min: '',
                            candle_max: '',
                            candle_last_min:'',
                            candle_last_max:'',
                            entrybase: 'exchange',
                          ordertype: 'market',
                            fundingtype: 'balance',
                            ma5:'',
                            ma10:'',
                            ma20:'',
                            ma60:'',
                            ma120:'',
                            
                    },

                  limit:{ //물린 오더 제한
                    total: 0, //총 100개까지
                    time: 0, // 재주문 딜레이 : 기준은 분(minutes)
                  },

                  profit: 0.5, //익절 기준 %
                  profit_position: 2, //포지션 기반 익절 기준 %
                        profit_time: '', //포지션 기반 익절 기준 %

                        profittype : 'percent',
                        profit_amount: 100,

                  stoploss: -2, //손절 기준 %
                  stoploss_position: -2, //포지션 기반 손절 기준 %

                  amount: 100, //매수금액
                  amounttype: 'normal', //매수금액 타입
                  synctype: false, //비중조절 타입
                  active:false,
            },
        ]
    ,



  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
/*
    botOption(){
      return this.$_.find(this.bots, {'id': this.selected_bot});
    }
  */  
    botOption(){
      return this.$_.find(this.$store.state.botOption, {'id': this.selected_bot});
    },
    botList(){
           //this.$store.state.systemOption.symbols; //현재 심볼들
        const self = this;

        const onlySetbotOption = self.$_.filter(self.$store.state.botOption, function(item) { return self.$_.includes( self.$store.state.systemOption.symbols, item.symbol ) });

      return onlySetbotOption;
    },
   /* botoption_json(){
      return JSON.stringify(this.$store.state.botOption);
    },*/




  },

  watch: {
    
    backtestSave() {
      this.saveOptions();
    },
    selected_bot() {
      this.saveOptions();
    },  
    date2() {
      this.saveOptions();
    },  
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.saveOptions();
    },
    nowOHLCV(){
      //console.log('nowOHLCV changed :', this.nowOHLCV);

      const self = this;

      this.resultPercent = 0;
      self.profitCount = 0;
      self.lossCount = 0;
      self.waitCount = 0;
      self.avgTime = 0;


                /**
                 * 봇들중 익절/손절 갯수 체크
                 */
                if(this.orders.length > 0){


                        this.orders.forEach(function (bot_item,index) {
                          
                          if(bot_item.profit !='' ){
                            //console.log('bot_item.profit',bot_item.profit);
                          
                              self.resultPercent = parseFloat(self.resultPercent) + parseFloat(bot_item.profit);

                              if(bot_item.profit < 0){
                                self.lossCount = self.lossCount + 1;
                              }
                              if(bot_item.profit > 0){
                                self.profitCount = self.profitCount + 1;
                              }

                              if(bot_item.closestamp){
                                self.orders[index].timelength= parseFloat(self.$setFloat(self.$moment.duration(self.$moment(bot_item.closestamp).diff(self.$moment(bot_item.timestamp))).asHours(),1));
                                //console.log(self.$setFloat(self.$moment.duration(self.$moment(bot_item.closestamp).diff(self.$moment(bot_item.timestamp))).asHours(),1));

                              }

          




                          }else{
                                self.waitCount = self.waitCount + 1;
                          }



                            
                        });

                        //평균보유시간
                        
                        const tmpOrders = self.$_.filter(self.orders, function(item) { return item.timelength});

                        //self.avgTime = tmpOrders;

                        self.avgTime = self.$_.meanBy(
                        tmpOrders, 
                        (itemOrder) => itemOrder['timelength']);

                }

                /**
                 * 봇들중 익절/손절 갯수 체크
                 */








    }
  },

  methods: {

    saveOptions(){

      if(this.backtestSave){
        this.$localData('backtestSave', '1');
        this.$localData('backtestOption', {
          selected_bot:this.selected_bot,
          date:this.date,
          date2:this.date2,
          });
      }else{
        this.$localData('backtestSave', '0');
      }

    },


     t(text,options) {
        return this.$t(text,options);
    },


    stopTest(){
      location.reload();
    },


    async btStartTest() {

      
      if(this.$i18n.locale =='ko'){
        
                this.headers =  [
                  {
                    text: "종목",
                    align: "start",
                    sortable: false,
                    value: "name",
                  },
                  { text: "포지션", value: "position" },
                  //{ text: "진입수량", value: "amount" },
                  { text: "진입가격", value: "open" },
                  { text: "종료가격", value: "close" },
                  { text: "실현손익(%)", value: "profit" },
                  //{ text: "잔고", value: "balance" },
                  //{ text: "레버리지", value: "leverage" },
                  { text: "거래시점 (UTC+9 Seoul)", value: "timestamp" ,width:120},
                  { text: "종료시점 (UTC+9 Seoul)", value: "closestamp" ,width:120 },
                  { text: "보유시간", value: "timelength"},
                ];

      }





        const botOption = this.$getBotInfo(this.selected_bot);
        //console.log('botOption: ', botOption);
        //const botOption = this.selected_bot;
        //console.log('botOption: ', this.selected_bot.id);
/**
 * 
              {
                  id : 'long',
                  name : '롱봇 사용자 전략',
                  symbol: 'BTC/USDT', //코인 종류
                  side: 'buy', //방향 buy/sell
                  catch:{
                      timeframe:'5m',
                      type:'continue' ,//연속봉 체크
                      count: 3, //
                      turn: true,
                    }, 
                  
                  amount: 100, //매수금액

                  limit:{ //물린 오더 제한
                    day:3, //일 3개
                    total:30, //총 30개까지
                    time: 15, // 재주문 딜레이 : 기준은 minutes
                  },

                  profit: 3, //익절 기준 %
                  stoploss: 3, //손절 기준 %
            },
 */




        //초기화
        this.orders = [];

        
    this.resultPercent = 0;
    this.lossCount= 0;
    this.profitCount= 0;
    this.waitCount= 0;
    this.avgTime=0;




this.testing = false;
this.loading = true;

      this.dataOHLCV = await this.$getOHLCV(botOption.symbol, botOption.catch.timeframe, { start: this.date, end: this.date2 });

      this.testing = true;
      setTimeout(() => {
        
      this.calcOrder(botOption);
      }, 0);
    },

    async calcOrder(botOption){
      const self = this;

      //const candle_all = dataOHLCV;
      //데이터 로그 확인
     //console.warn(`${this.$moment(this.dataOHLCV[0][0]).format("yyyy-MM-DD H:mm:ss")} ~ ${this.$moment(this.dataOHLCV[this.dataOHLCV.length - 1][0]).format("yyyy-MM-DD H:mm:ss")}`,this.dataOHLCV);


        //백데이터대상에 지정된 전체 캔들을 탐색
        //백데이터의 오더는 과거에서부터 오더체결해야하므로, 과거순 그대로 진행
        this.dataOHLCV.forEach(function (item, index) {

          //const targetRange = botOption.catch.count; //검토할 캔들 갯수, 백테스트에서는 현재의 범위가 아닌, 과
          const targetRange = botOption.catch.pattern_length; //검토할 캔들 갯수, 백테스트에서는 현재의 범위가 아닌, 과
          const timestamp = item[0];
          //const valueOpen = item[1];
          const valueHigh = item[2];
          const valueLow = item[3];
          const valueClose = item[4];
          
          self.nowOHLCV = item;

          
          
          //최소 5개봉부터 연속 등락체크가 가능하므로, 0~4 까지의 5개 캔들은 무시한다. S
          if(index+1 > targetRange){ 
              
              //const targetCandles = self.dataOHLCV.slice(index-targetRange, index);
              //const targetCandles = self.dataOHLCV.slice(index, self.dataOHLCV.length);
              const targetCandles = self.dataOHLCV.slice(0,index+1);
              let targetDetails = self.$candleDetail(targetCandles,targetRange,'backtest');

                  targetDetails.rsi1 = self.$_.last(self.$calcRsi(targetCandles,botOption.catch.rsi1_length));
                  targetDetails.rsi2 = self.$_.last(self.$calcRsi(targetCandles,botOption.catch.rsi2_length));


              let isCatched = false;
              let position = '';

              /***
               * 3연속 하락 -> 1상승 ->재하락 일 경우, 상승기대 롱매수
               */
                
              //console.log('targetRange',targetRange);
             // console.log('self.dataOHLCV',self.dataOHLCV, index);
              //console.log('targetCandles',targetCandles);
            //  console.log('targetDetails',targetDetails);
                
              //let sliceSideFlow = targetDetails.sideFlow.slice(-(botOption.catch.pattern.length));

              //console.log(this.getBotInfo(this.bot_id).symbol,this.getBotInfo(this.bot_id).side,'v2 패턴비교');
              
              let sliceSideFlow = targetDetails.sideFlow;
              const pattern_array = sliceSideFlow.split("");
              //console.log('sliceSideFlow',sliceSideFlow);
              //console.log('pattern_array',pattern_array);


              let isPatternPassed =  true;
              self.$_.forEachRight(pattern_array, function(item, index) {
                  //const lastnum =botOption.catch['pattern_'+(pattern_old.length-index)];
                  const  lastnum = Math.abs(index-pattern_array.length);
                  //console.log('last candle',lastnum,item);
                  //console.log('pattern_'+lastnum,botOption.catch['pattern_'+lastnum])
                  if(item != botOption.catch['pattern_'+lastnum] &&  botOption.catch['pattern_'+lastnum] != '*' ){
                    //console.log('패턴 불일치로 차단 : '+lastnum, item, botOption.catch['pattern_'+lastnum]);
                    isPatternPassed = false; 
                  }else{
                    //console.log('패턴 일치 혹은 패스'+lastnum, item, botOption.catch['pattern_'+lastnum]);
                  }

                  //botOption.catch['pattern_'+lastnum] = item;
              });
              /*
              if(!isPatternPassed){
                    console.warn(botOption.name +'최근오더 : ' ,'패턴v2 불일치 최종 차단');
                  return 
              }
              */


//console.log('dataOHLCV index',index);



        //패턴범위 크기 
              if( self.$nvl(botOption.catch.candle_min,false) && botOption.catch.pattern_length > 1 ){

                //변동폭이 미니멈보다 작다면 중단
                //if(botOption.catch.candle_min > Math.abs(candleDetail.change) && botOption.catch.pattern != '*'){ //패턴 v1
                if(botOption.catch.candle_min > Math.abs(targetDetails.change) ){
                //console.log(botOption.name +'최근오더 : ' , `캔들범위크기 기준(최소) 미달(${botOption.catch.candle_min})로 자동주문 실행방지`, Math.abs(targetDetails.change));
                  //return false;
                  
                    isPatternPassed = false; 
                }else{
                  //console.log(botOption.name +'최근오더 : ' , `캔들범위크기 기준(최소) 적합(${botOption.catch.candle_min}) `, Math.abs(targetDetails.change));
                }


              }

              
              if( self.$nvl(botOption.catch.candle_max,false) && botOption.catch.pattern_length > 1 ){


                //변동폭이 미니멈보다 작다면 중단
                //if(botOption.catch.candle_max < Math.abs(targetDetails.change) && botOption.catch.pattern != '*'){//패턴 v1
                if(botOption.catch.candle_max < Math.abs(targetDetails.change) ){
                  //console.log(botOption.name +'최근오더 : ' , `캔들범위크기 기준(최대) 초과(${botOption.catch.candle_max})로 자동주문 실행방지`, Math.abs(targetDetails.change));
                  //return false;
                  
                    isPatternPassed = false; 
                }else{
                  //console.log(botOption.name +'최근오더 : ' , `캔들범위크기 기준(최대) 적합(${botOption.catch.candle_max}) `, Math.abs(targetDetails.change));

                }


              }
        //패턴범위 크기 


        //직전패턴 크기 
              if( self.$nvl(botOption.catch.candle_last_min,false) && botOption.catch.pattern_length > 0 ){

                //변동폭이 미니멈보다 작다면 중단
                //if(botOption.catch.candle_last_min > Math.abs(targetDetails.lastChange) && botOption.catch.pattern != '*'){ //패턴 v1
                if(botOption.catch.candle_last_min > Math.abs(targetDetails.lastChange) ){
                //console.log(botOption.name +'최근오더 : ' , `직전캔들크기 기준(최소) 미달(${botOption.catch.candle_last_min})로 자동주문 실행방지`, Math.abs(targetDetails.lastChange));
                  //return false;
                  
                    isPatternPassed = false; 
                }else{
                  //console.log(botOption.name +'최근오더 : ' , `직전캔들크기 기준(최소) 적합(${botOption.catch.candle_last_min}) `, Math.abs(targetDetails.lastChange));
                }


              }

              
              if( self.$nvl(botOption.catch.candle_last_max,false) && botOption.catch.pattern_length > 0 ){


                //변동폭이 미니멈보다 작다면 중단
                //if(botOption.catch.candle_last_max < Math.abs(targetDetails.lastChange) && botOption.catch.pattern != '*'){//패턴v1
                if(botOption.catch.candle_last_max < Math.abs(targetDetails.lastChange) ){
                  //console.log(botOption.name +'최근오더 : ' , `직전캔들크기 기준(최대) 초과(${botOption.catch.candle_last_max})로 자동주문 실행방지`, Math.abs(targetDetails.lastChange));
                  //return false;
                  
                    isPatternPassed = false; 
                }else{
                  //console.log(botOption.name +'최근오더 : ' , `직전캔들크기 기준(최대) 적합(${botOption.catch.candle_last_max}) `, Math.abs(targetDetails.lastChange));

                }


              }
        //직전패턴 크기 




              if(
                botOption.side == 'buy' //buy 롱봇인경우 캣치조건

                && isPatternPassed //패턴이 맞다면
                 ){
                //console.error('롱 지금이니!',item);
                isCatched = true;
                position = 'buy'; 
              }
              if(
                botOption.side == 'sell' //buy 숏봇인경우 캣치조건
                && isPatternPassed //패턴이 맞다면
                 ){
                //console.error('숏 지금이니!',item);
                isCatched = true;
                position = 'sell';
              }


        //ma5비교

              if( self.$nvl(botOption.catch.ma5,false)){
                 let isMa5Status = '';

                //변동폭이 미니멈보다 작다면 중단
                if(valueClose >= targetDetails.average_ma5){
                  isMa5Status = 'up';
                }
                if(valueClose < targetDetails.average_ma5){
                  isMa5Status = 'down';
                }

                //원하는 ma상태가 아닌경우, 중단.
                if(botOption.catch.ma5 != isMa5Status){
                      //console.log(botOption.name +'최근오더 : ' , `ma5 조건 (${botOption.catch.ma5} / 상태 ${isMa5Status} / ma ${targetDetails.average_ma5} / 종가 ${valueClose}) 가 달라 자동주문 실행방지`,targetDetails.date);
                  //return false;        
                  isCatched = false;
                }else{
                      //console.log(botOption.name +'최근오더 : ' , `ma5 조건 (${botOption.catch.ma5} / 상태 ${isMa5Status} / ma ${targetDetails.average_ma5} / 종가 ${valueClose}) 가 적합`,targetDetails.date);
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < 5){  
                  isCatched = false;
                  //console.log(botOption.name + 'ma5 조건 충족 미달(캔들부족)');
                }

              }
        //ma5비교


        //ma10비교

              if( self.$nvl(botOption.catch.ma10,false)){
                 let isMa10Status = '';

                //변동폭이 미니멈보다 작다면 중단
                if(valueClose >= targetDetails.average_ma10){
                  isMa10Status = 'up';
                }
                if(valueClose < targetDetails.average_ma10){
                  isMa10Status = 'down';
                }

                //원하는 ma상태가 아닌경우, 중단.
                if(botOption.catch.ma10 != isMa10Status){
                      //console.log(botOption.name +'최근오더 : ' , `ma10 조건 (${botOption.catch.ma10} / 상태 ${isMa10Status} / ma ${targetDetails.average_ma10} / 종가 ${valueClose}) 가 달라 자동주문 실행방지`,targetDetails.date);
                  //return false;        
                  isCatched = false;       
                }else{
                     // console.log(botOption.name +'최근오더 : ' , `ma10 조건 (${botOption.catch.ma10} / 상태 ${isMa10Status} / ma ${targetDetails.average_ma10} / 종가 ${valueClose}) 가 적합`,targetDetails.date);
                  
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < 10){  
                  isCatched = false;
                 // console.log(botOption.name + 'ma10 조건 충족 미달(캔들부족)');
                }

              }
        //ma10비교

        //ma20비교

              if( self.$nvl(botOption.catch.ma20,false)){
                 let isMa20Status = '';

                //변동폭이 미니멈보다 작다면 중단
                if(valueClose >= targetDetails.average_ma20){
                  isMa20Status = 'up';
                }
                if(valueClose < targetDetails.average_ma20){
                  isMa20Status = 'down';
                }

                //원하는 ma상태가 아닌경우, 중단.
                if(botOption.catch.ma20 != isMa20Status){
                     // console.log(botOption.name +'최근오더 : ' , `ma20 조건 (${botOption.catch.ma20} / 상태 ${isMa20Status} / ma ${targetDetails.average_ma20} / 종가 ${valueClose}) 가 달라 자동주문 실행방지`,targetDetails.date);
                  //return false;        
                  isCatched = false;        
                }else{
                     // console.log(botOption.name +'최근오더 : ' , `ma20 조건 (${botOption.catch.ma20} / 상태 ${isMa20Status} / ma ${targetDetails.average_ma20} / 종가 ${valueClose}) 가 적합`,targetDetails.date);
                  
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < 20){  
                  isCatched = false;
                  //console.log(botOption.name + 'ma20 조건 충족 미달(캔들부족)');
                }

              }
        //ma20비교
        //ma60비교

              if( self.$nvl(botOption.catch.ma60,false)){
                 let isMa60Status = '';

                //변동폭이 미니멈보다 작다면 중단
                if(valueClose >= targetDetails.average_ma60){
                  isMa60Status = 'up';
                }
                if(valueClose < targetDetails.average_ma60){
                  isMa60Status = 'down';
                }

                //원하는 ma상태가 아닌경우, 중단.
                if(botOption.catch.ma60 != isMa60Status){
                      //console.log(botOption.name +'최근오더 : ' , `ma60 조건 (${botOption.catch.ma60} / 상태 ${isMa60Status} / ma ${targetDetails.average_ma60} / 종가 ${valueClose}) 가 달라 자동주문 실행방지`,targetDetails.date);
                  //return false;        
                  isCatched = false;       
                }else{
                     // console.log(botOption.name +'최근오더 : ' , `ma60 조건 (${botOption.catch.ma60} / 상태 ${isMa60Status} / ma ${targetDetails.average_ma60} / 종가 ${valueClose}) 가 적합`,targetDetails.date);
                  
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < 60){  
                  isCatched = false;
                 // console.log(botOption.name + 'ma60 조건 충족 미달(캔들부족)');
                }

              }
        //ma60비교

        //ma120비교

              if( self.$nvl(botOption.catch.ma120,false)){
                 let isMa120Status = '';

                //변동폭이 미니멈보다 작다면 중단
                if(valueClose >= targetDetails.average_ma120){
                  isMa120Status = 'up';
                }
                if(valueClose < targetDetails.average_ma120){
                  isMa120Status = 'down';
                }

                //원하는 ma상태가 아닌경우, 중단.
                if(botOption.catch.ma120 != isMa120Status){
                     // console.log(botOption.name +'최근오더 : ' , `ma120 조건 (${botOption.catch.ma120} ma / 상태 ${isMa120Status} / ${targetDetails.average_ma120} / 종가 ${valueClose}) 가 달라 자동주문 실행방지`,targetDetails.date);
                  //return false;        
                  isCatched = false;        
                }else{
                    //  console.log(botOption.name +'최근오더 : ' , `ma120 조건 (${botOption.catch.ma120} ma / 상태 ${isMa120Status} / ${targetDetails.average_ma120} / 종가 ${valueClose}) 가 적합`,targetDetails.date);
                  
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < 120){  
                  isCatched = false;
                 // console.log(botOption.name + 'ma120 조건 충족 미달(캔들부족)');
                }

              }
        //ma120비교



        //rsi 1 범위 
              if( self.$nvl(botOption.catch.rsi1_min,false) && self.$nvl(botOption.catch.rsi1_length,false)){
                //rsi 1지수가 미니멈보다 작다면 중단
                if(botOption.catch.rsi1_min > Math.abs(targetDetails.rsi1)){
                //console.log(botOption.name +'최근오더 : ' , `rsi1 기준(최소) 미달(${botOption.catch.rsi1_min})로 자동주문 실행방지`, Math.abs(targetDetails.rsi1));
                  //return false;        
                  isCatched = false;     
                }else{
                 // console.log(botOption.name +'최근오더 : ' , `rsi1 기준(최소) 적합(${botOption.catch.rsi1_min}) `, Math.abs(targetDetails.rsi1));
                }

                

                //측정하기위한 캔들 수 부족하면 중단
                if(index < botOption.catch.rsi1_length){  
                  isCatched = false;
                //  console.log(botOption.name + 'rsi1 조건 충족 미달(캔들부족)',index);
                }
                
              }

              
              if( self.$nvl(botOption.catch.rsi1_max,false)  && self.$nvl(botOption.catch.rsi1_length,false)){


                //rsi 1지수가 맥시멈보다 크다면 중단
                if(botOption.catch.rsi1_max < Math.abs(targetDetails.rsi1)){
                  //console.log(botOption.name +'최근오더 : ' , `rsi1 기준(최대) 초과(${botOption.catch.rsi1_max})로 자동주문 실행방지`, Math.abs(targetDetails.rsi1));
                  //return false;        
                  isCatched = false;     
                }else{
                 // console.log(botOption.name +'최근오더 : ' , `rsi1 기준(최대) 적합(${botOption.catch.rsi1_max}) `, Math.abs(targetDetails.rsi1));
                }


                //측정하기위한 캔들 수 부족하면 중단
                if(index < botOption.catch.rsi1_length){  
                  isCatched = false;
                 // console.log(botOption.name + 'rsi1 조건 충족 미달(캔들부족)',index);
                }


              }
        //rsi 1 범위 

        //rsi 2 범위 
              if( self.$nvl(botOption.catch.rsi2_min,false) && self.$nvl(botOption.catch.rsi2_length,false)){
                //rsi 1지수가 미니멈보다 작다면 중단
                if(botOption.catch.rsi2_min > Math.abs(targetDetails.rsi2) ){
                //console.log(botOption.name +'최근오더 : ' , `rsi2 기준(최소) 미달(${botOption.catch.rsi2_min})로 자동주문 실행방지`, Math.abs(targetDetails.rsi2));
                  //return false;        
                  isCatched = false;     
                }else{
                  //console.log(botOption.name +'최근오더 : ' , `rsi2 기준(최소) 적합(${botOption.catch.rsi2_min}) `, Math.abs(targetDetails.rsi2));
                }


                //측정하기위한 캔들 수 부족하면 중단
                if(index < botOption.catch.rsi2_length){  
                  isCatched = false;
                  //console.log(botOption.name + 'rsi2 조건 충족 미달(캔들부족)',index);
                }
              }

              
              if( self.$nvl(botOption.catch.rsi2_max,false)  && self.$nvl(botOption.catch.rsi2_length,false)){


                //rsi 1지수가 맥시멈보다 크다면 중단
                if(botOption.catch.rsi2_max < Math.abs(targetDetails.rsi2) ){
                  //console.log(botOption.name +'최근오더 : ' , `rsi2 기준(최대) 초과(${botOption.catch.rsi2_max})로 자동주문 실행방지`, Math.abs(targetDetails.rsi2));
                  //return false;        
                  isCatched = false;     
                }else{
                 // console.log(botOption.name +'최근오더 : ' , `rsi2 기준(최대) 적합(${botOption.catch.rsi2_max}) `, Math.abs(targetDetails.rsi2));
                }

                //측정하기위한 캔들 수 부족하면 중단
                if(index < botOption.catch.rsi2_length){  
                  isCatched = false;
                 // console.log(botOption.name + 'rsi2 조건 충족 미달(캔들부족)',index);
                }


              }
        //rsi 2 범위 





















/*

              if(
                botOption.side == 'buy' //buy 롱봇인경우 캣치조건

                && sliceSideFlow == botOption.catch.pattern //설정값에서 패턴 불러옴
                //&& targetDetails.sideFlow == '---+-' //+252 (145/61) 단독 0      330
                //&& targetDetails.sideFlow == '+++-+' //+276 (168/66) 단독 1      345
                //&& targetDetails.sideFlow == '+++++' //+294 단독 1                 282

                //&& targetDetails.sideFlow == '---+-' //+177 (89/30) 평균체크- 같이 2
                //&& targetDetails.average > valueHigh //기존평균이하일때

                //&& targetDetails.sideFlow == '---+-' //+75 (89/30) 평균체크+ 같이 3
                //&& targetDetails.average < valueHigh //기존평균이하일때

                //&& targetDetails.sideFlow == '+++-+' //+15 (9/4) 평균체크- 같이 4
                //&& targetDetails.average > valueHigh //기존평균이하일때

                //&& targetDetails.sideFlow == '+++-+' //+261 (149/62) 평균체크- 같이 5
                //&& targetDetails.average < valueHigh //기존평균이하일때


                 //&& targetDetails.average > valueHigh  //지난 5개 캔들동안 평균가대비 낮은 상태라면.. 하락세에서는 손실방지되지만, 상승세에서는 수익이 다소 적어짐, 향후 옵션으로 조정하거나 상승세/하락세 판별후 자동 온오프 되면 좋겠음.                 
                 ){
                console.error('롱 지금이니!',item);
                isCatched = true;
                position = 'buy'; 
              }
              if(
                botOption.side == 'sell' //buy 숏봇인경우 캣치조건

                && sliceSideFlow == botOption.catch.pattern //설정값에서 패턴 불러옴
                //&& targetDetails.sideFlow == '-----'  
                // && targetDetails.average < valueLow  //지난 5개 캔들동안 평균가대비 낮은 상태라면.. 하락세에서는 손실방지되지만, 상승세에서는 수익이 다소 적어짐, 향후 옵션으로 조정하거나 상승세/하락세 판별후 자동 온오프 되면 좋겠음.                 
                 ){
                console.error('숏 지금이니!',item);
                isCatched = true;
                position = 'sell';
              }
              
              */

             

              //현재 봇 갯수가 지정된 갯수보다 크면 위조건에 상관없이 취소
              let botCountNow = (self.$_.filter(self.orders, { position: position,closestamp:'' })).length;
              if(botCountNow >= botOption.limit.total && botOption.limit.total != 0){
                isCatched = false;
                //console.warn('botCountNow 초과',botCountNow);
              }else{
                if(botCountNow > 0){
                  //console.log('botCountNow',botCountNow);
                }
              }
              //현재 봇 갯수가 지정된 갯수보다 크면 위조건에 상관없이 취소
//                console.log('botCountNow option',botOption.limit.total);




              //같은 봇 방향의 최근 주문이 지정된 시간 내라면 오더x
              let botLastOrder =  (self.$_.filter(self.orders, { position: position })) ; //같은 봇방향의 최근 주문
              if(botLastOrder.length > 0){
                  const lastBotOrder = self.$_.first(botLastOrder);
                  //console.log('botLastOrder',lastBotOrder.name);  
                  //console.log('botLastOrder timestamp',lastBotOrder.timestamp);

                  let t1 = self.$moment(lastBotOrder.timestamp);
                  let t2 = self.$moment(item[0]); //현재값이 아닌 현재 캔들의 시간으로 비교 (테스트이기때문)

                  //console.log('botLastOrder t1(last)',self.$moment(t1).format("yyyy-MM-DD H:mm:ss"));
                  //console.log('botLastOrder t2(now)',self.$moment(t2).format("yyyy-MM-DD H:mm:ss"));

                  let diffOrderTime = self.$moment.duration(t2.diff(t1)).asMinutes();
                  //console.log('botLastOrder diffnow',diffOrderTime);

                  if(diffOrderTime <= botOption.limit.time){ //차이(분) <= 지정된 시간(분)
                    isCatched = false;
                    //console.warn('botLastOrder 너무짧은시간',diffOrderTime);
                  }
              }
              
              //같은 봇 방향의 최근 주문이 지정된 시간 내라면 오더x
              




              //캣치 시, 위에서 정의된 조건으로 롱/숏 진행
              if(isCatched){ 
                



                      let orderObj = {
                        idx: index,
                        name: self.selected_bot,
                        position: position,
                        amount: 100,
                        open: valueClose,
                        //open: valueOpen,
                        close: '',
                        profit: '',
                        balance: '',
                        leverage: 1,
                        timestamp: timestamp,
                        closestamp: '',
                      };
                      self.submitOrder(orderObj);
                      //console.log('지금이니! '+botOption.side , orderObj);
              }
              //캣치 시, 위에서 정의된 조건으로 롱/숏 진행




                /**
                 * 봇들중 수익낸 것들 정리
                 */
                if(self.orders.length > 0){


                      if(botOption.side=='buy'){

                              self.orders.forEach(function (bot_item, bot_index) {
                                let botProfitPer = botOption.profit;
                                let botStoplossPer = botOption.stoploss;
                                const targetProfit = self.$calcPercentValue(bot_item.open,botProfitPer);
                                const targetStoploss = self.$calcPercentValue(bot_item.open,botStoplossPer);

                                //console.log('backtest',bot_item,bot_index);

                                //익절 3%
                                if(botOption.active.profit && valueHigh > bot_item.open+targetProfit && self.orders[bot_index].closestamp =='' &&
                                  self.orders[bot_index].timestamp < timestamp
                                  ){
                                 // console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetProfit.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetProfit;
                                  self.orders[bot_index].profit = botProfitPer;
                                  //self.orders[bot_index].profit = self.$calcPercentDiff(self.orders[bot_index].open,self.orders[bot_index].close);
                                  self.orders[bot_index].closestamp = timestamp;
                                  //self.orders[bot_index].closestamp = self.$moment(timestamp).format("yyyy-MM-DD HH:mm:ss");
                                }

                                //손절 -3%
                                if(botOption.active.stoploss && valueLow < bot_item.open + targetStoploss &&  self.orders[bot_index].closestamp =='' &&
                                  self.orders[bot_index].timestamp < timestamp
                                  ){
                                  //console.error(self.$moment(timestamp).format("yyyy-MM-DD HH:mm:ss"),bot_item.open,parseFloat(targetStoploss.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetStoploss;
                                  self.orders[bot_index].profit = botStoplossPer;
                                  self.orders[bot_index].closestamp = timestamp;
                                }
                                  
                              });
                      }

                      if(botOption.side=='sell'){

                              self.orders.forEach(function (bot_item, bot_index) {
                                let botProfitPer = -(botOption.profit); //2.5
                                let botStoplossPer = -(botOption.stoploss); //-2



                                const targetProfit = self.$calcPercentValue(bot_item.open,botProfitPer);
                                const targetStoploss = self.$calcPercentValue(bot_item.open,botStoplossPer);

                                //console.log('backtest',bot_item,bot_index);

                                //익절 3%
                                if(botOption.active.profit && valueLow < bot_item.open+targetProfit && self.orders[bot_index].closestamp =='' &&
                                  self.orders[bot_index].timestamp < timestamp
                                  ){
                                 // console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetProfit.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetProfit ;
                                  self.orders[bot_index].profit = -(botProfitPer);
                                  //self.orders[bot_index].profit = self.$calcPercentDiff(self.orders[bot_index].open,self.orders[bot_index].close);
                                  self.orders[bot_index].closestamp = timestamp;
                                  //self.orders[bot_index].closestamp = self.$moment(timestamp).format("yyyy-MM-DD HH:mm:ss");
                                }

                                //손절 -3%
                                if(botOption.active.stoploss && valueHigh > bot_item.open + targetStoploss &&  self.orders[bot_index].closestamp =='' &&
                                  self.orders[bot_index].timestamp < timestamp
                                  ){
                                  //console.error(self.$moment(timestamp).format("yyyy-MM-DD HH:mm:ss"),bot_item.open,parseFloat(targetStoploss.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetStoploss ;
                                  self.orders[bot_index].profit = -(botStoplossPer);
                                  self.orders[bot_index].closestamp = timestamp;
                                }

                                /*
                                const targetProfit = self.$calcPercentValue(bot_item.open,botProfitPer);
                                const targetStoploss = self.$calcPercentValue(bot_item.open,botStoplossPer);


                                //익절시 비교값 저가와 비교, 숏봇
                                let percentageProfit = parseFloat(self.$setFloat(self.$calcPercentDiff(valueLow,bot_item.open),2));

                                //익절시 비교값 고가와 비교, 롱봇
                                let percentageLoss = parseFloat(self.$setFloat(self.$calcPercentDiff(valueHigh,bot_item.open),2));

                                //익절
                                if(percentageProfit < botProfitPer){
                                  //console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetProfit.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = targetProfit;
                                  self.orders[bot_index].profit = -(botProfitPer);
                                  self.orders[bot_index].closestamp = timestamp;
                                }

                                //손절
                                if(percentageLoss > botStoplossPer){
                                  //console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetStoploss.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = targetStoploss;
                                  self.orders[bot_index].profit = -(botStoplossPer);
                                  self.orders[bot_index].closestamp = timestamp;
                                }
                                */


/*

                                const targetProfit = self.$calcPercentValue(bot_item.open,botProfitPer);
                                const targetStoploss = self.$calcPercentValue(bot_item.open,botStoplossPer);


                                //console.log(bot_item,bot_index);
                                  //self.orders[bot_index].profit = percentProfit;

                                //손절 3%
                                if(botOption.active.stoploss && valueHigh > targetStoploss && self.orders[bot_index].closestamp =='' ){
                                  console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetStoploss.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetProfit;
                                  self.orders[bot_index].profit = botStoplossPer;
                                  self.orders[bot_index].closestamp = timestamp;
                                }

                                //익절 -3%
                                if(botOption.active.profit && valueLow < targetProfit &&  self.orders[bot_index].closestamp =='' ){
                                  console.error(self.$moment(timestamp).format("yyyy-MM-DD H:mm:ss"),bot_item.open,parseFloat(targetProfit.toFixed(2)),valueClose);
                                  self.orders[bot_index].close = bot_item.open + targetProfit;
                                  self.orders[bot_index].profit = botProfitPer;
                                  self.orders[bot_index].closestamp = timestamp;
                                }
*/



                                  
                              });



                      }




                }

                /**
                 * 봇들중 수익낸 것들 정리
                 */





          }
          //최소 5개봉부터 연속 등락체크가 가능하므로, 0~4 까지의 5개 캔들은 무시한다. E

        });
        //백데이터대상에 지정된 전체 캔들을 탐색
        //백데이터의 오더는 과거에서부터 오더체결해야하므로, 과거순 그대로 진행




        this.loading = false;
        //this.testing = false;
    },


    async submitOrder(orderObj) {
      const self = this;
                      self.orders.unshift(orderObj);

    },









    async checkBuyOption() {

      


      let candleCount = 10 + 1; //가져오는 캔들중 첫번째는 현재 값으로 사용하기 위함.
      let self = this;

      //let targetData = self.$_.reverse(this.dataOHLCV);

      //백데이터대상에 지정된 전체 캔들을 탐색
      //백데이터의 오더는 과거에서부터 오더체결해야하므로, 과거순 그대로 진행
      this.dataOHLCV.forEach(function (itemOHLCV, index) {
        //console.log(index,itemOHLCV);

        console.log(`-----------------------`);

        //개별 봉탐색 범위를 재탐색

        let targetCandles = [];
        //타켓 내부의 순서는 최신순서
        self.dataOHLCV.forEach(function (subItem, subIndex) {
          if (subIndex >= index && subIndex < index + candleCount) {
            targetCandles.push(subItem); //최근것부터 넣기위해 push로 넣음
            //targetCandles.unshift(subItem);
          }
        });

        //최신순으로 뒤집는다.
        targetCandles = self.$_.reverse(targetCandles);

        targetCandles.forEach((item) => {
          let datetime = self.$moment(item[0]).format("yyyy-MM-DD H:mm:ss");

          //판단할 수 있는 캔들 수가 충족되지 않는 끝데이터는 무시한다.
          if (targetCandles.length >= candleCount) {
            console.log(datetime, item[0], item[1]);
          }
        });

        //판단할 수 있는 캔들 수가 충족되지 않는 끝데이터는 무시한다.
        if (targetCandles.length >= candleCount) {
          let startDate = self
            .$moment(targetCandles[0][0])
            .format("yyyy-MM-DD HH:mm:ss");
          let endDate = self
            .$moment(targetCandles[targetCandles.length - 1][0])
            .format("yyyy-MM-DD HH:mm:ss");
          console.log(
            `[${index} 참고데이터 / ${index}  ${startDate}~ ${endDate}]`,
            targetCandles
          ); //최종적으로 자동매매조건에 활용할 캔들들

          console.log(
            "해당일종가(C)데이터:",
            targetCandles[0][4],
            targetCandles[0][4]
          );
          //let candlesDetail = self.candleDetail(targetCandles);
          //console.log(`${index}상세데이터`,candlesDetail);

          //해당일 종가(c)데이터 <비교> 옵션값
          let nowItem = targetCandles[0];

          //첫째는 현재값이므로 제외
          self.$_.pullAt(targetCandles, 0); //제외!
          console.log("현재값빠진 것 확인:", targetCandles.length);

          let isCatched = self.isCatched(nowItem, targetCandles);

          let timestamp = nowItem[0];
          let valueClose = nowItem[4];
          let orderObj = {
            id: null,
            name: index + self.selected_bot,
            position: "buy",
            amount: 100,
            open: valueClose,
            close: null,
            profit: null,
            balance: null,
            leverage: 1,
            timestamp: timestamp,
          };
          self.orders.push(orderObj);

          if (isCatched) {
            /*
                              console.log('캣치'+index);
                                      //오더실행
                                        let timestamp = nowItem[0];
                                        let valueClose = nowItem[4];
                                        let orderObj =  {
                                            id: null,
                                            name: index+"BTC/USDT",
                                            position: "buy",
                                            amount: 100,
                                            open: valueClose,
                                            close: null,
                                            profit: null,
                                            balance: null,
                                            leverage: 1,
                                            timestamp: timestamp,
                                          };
                                      self.submitOrder(orderObj);
                                      //오더실행
                                      */
            /*
                              //이익실현
                                        self.orders.forEach(function (orderItem, orderIndex) { //봇들 루프
  
  
                                              let calcPer = self.$calcPercentDiff(orderItem.open,valueClose); //봇 시작가 , 현재종가 비교
  
  
                                                    
                                          if(
                                            timestamp > orderItem.timestamp && (calcPer >= 3 || calcPer < -3) && !self.$nvl(orderItem.close)
                                          ){
  
                                                  console.error('orderItem.timestamp'+orderIndex+self.$moment(timestamp).format('yyyy-MM-DD hh:mm:ss'),'>'+
                                                    self.$moment(orderItem.timestamp).format('yyyy-MM-DD hh:mm:ss'));
  
                                                    console.warn('주문진입가',orderItem.open);
                                                    console.warn('주문종료가',valueClose);
                                                    console.error('등락%',calcPer);
                                                if(valueClose){
                                                                          self.orders[orderIndex].close = valueClose;
                                                                          self.orders[orderIndex].profit = calcPer;
                                                                          self.orders[orderIndex].closestamp = self.$_.now();
                                                }
  
                                          }
  
  
  
  
                                        });
                                        
                            //이익실현
                            */
          }
        }
      });

      //5/2봉 평균균가보다 높으며,

      //5봉평균의 값보다 하락했다면, (옵션)

      /*
          let self = this;
          this.dataOHLCV.forEach(function (itemOHLCV, i) {
            //let datetime = this.$moment(itemOHLCV[0]).format('yyyy-MM-DD hh:mm:ss');
              console.log(`${i}번째 봉 거래량: ${itemOHLCV[5]}`);           
          });
          */

      //

      //5번째 봉까지의 최고가를 현재 고가가 뛰어넘고,

      //5봉평균의 최저가보다 높으며
      //5/2봉 평균균가보다 높으며,

      //5봉평균의 값보다 하락했다면, (옵션)

      const totalProfit = self.$_.meanBy(
        self.orders,
        (itemOrder) => itemOrder["profit"]
      );
      console.log("totalProfit:", totalProfit);
    },
    isCatched(nowItem, targetCandles) {
      //let timestamp = nowItem[0];
      //let openValue = nowItem[1];
      let highValue = nowItem[2];
      //let lowValue = nowItem[3];
      let valueClose = nowItem[4];
      //let volumeValue = nowItem[5];

      let candlesDetail = this.candleDetail(targetCandles);

      //현재가 고가가 평균 고가보다 높다면,
      if (highValue > candlesDetail.high) {
        console.log("비교:", `${highValue} > ${candlesDetail.high}`);
      }

      //현재 종가가  타겟 평균가보다 높다면
      if (valueClose > candlesDetail.average) {
        return true;
      }
    },

    botItemCheck(index) {
      const self = this;
      let targetCandles = self.dataOHLCV[index];
      let candlesDetail = self.candleDetail(targetCandles);
      console.log(`${index}상세데이터`, candlesDetail);

      /*
                              봇 실행위치
                          */

      let isCatchOrder = false;

      let nowCandleHigh = self.dataOHLCV[index][2];
      let nowCandleClose = self.dataOHLCV[index][4];
      let nowCandleLow = self.dataOHLCV[index][3];
      let nowCandleTimestamp = self.dataOHLCV[index][0];

      //  console.warn(nowCandleHigh , candlesDetail.high );

      if (
        nowCandleHigh >= candlesDetail.high && //5번째 봉까지의 최고가를 현재 고가가 뛰어넘고,
        nowCandleLow > candlesDetail.low && //5봉평균에 비해 현재 최저가가 더높으며,
        nowCandleClose > candlesDetail.average //5봉평균의 종가가 더 높으면,
      ) {
        isCatchOrder = true;
      }

      if (isCatchOrder) {
        console.warn("캐치 종가:", nowCandleClose);
        self.buyCount = self.buyCount + 1;
        //self.amount = self.amount -100;

        let orderObj = {
          id: null,
          name: index + self.selected_bot,
          position: "buy",
          amount: 100,
          open: nowCandleClose,
          close: null,
          profit: null,
          balance: null,
          leverage: 1,
          timestamp: nowCandleTimestamp,
        };
        self.submitOrder(orderObj);
      }

      /*
                              봇 실행위치
                          */

      /*
                              이익실현 실행위치
                              
  
                                        self.orders.forEach(function (orderItem, orderIndex) {
  
  
                                              let calcPer = self.$calcPercentDiff(orderItem.open,nowCandleClose);
  
  
                                                    
                                          if(
                                            nowCandleTimestamp > orderItem.timestamp && (calcPer >= 3 || calcPer < -3) && !self.$nvl(orderItem.close)
                                          ){
  
                                                  console.error('orderItem.timestamp'+orderIndex+self.$moment(nowCandleTimestamp).format('yyyy-MM-DD hh:mm:ss'),'>'+
                                                    self.$moment(orderItem.timestamp).format('yyyy-MM-DD hh:mm:ss'));
  
                                                    console.warn('주문진입가',orderItem.open);
                                                    console.warn('주문종료가',nowCandleClose);
                                                    console.error('등락%',calcPer);
                                                if(nowCandleClose){
                                                                          self.orders[orderIndex].close = nowCandleClose;
                                                                          self.orders[orderIndex].profit = calcPer;
                                                                          self.orders[orderIndex].closestamp = self.$_.now();
                                                }
  
                                          }
  
  
  
  
                                        });
  
  
  
  
  
  
  
  
                          /*
                              이익실현 실행위치
                          */
    },


    async isCanOrder(itemOHLCV) {
      console.log(itemOHLCV);
      return "매수포착";
    },

    getColor(profit) {
      if (profit > 0) return "red";
      else if (profit < 0) return "blue";
      else return "";
    },

    formatDate(date) {
      if (!date) return null;
      return date;
      /*const [month, day, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;*/
    },
    parseDate(date) {
      if (!date) return null;
      return date;
      /*const [month, day, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;*/
    },

    getHeaderDate(date) {
      return date;
    },

    getDay(date) {
      
      let daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

      if(this.$i18n.locale =='ko'){
         daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
      }
        let i = new Date(date).getDay(date);
        return daysOfWeek[i];
    },
    getMonth(date) {
      
      let monthName = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ];

      if(this.$i18n.locale =='ko'){monthName = [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ];
      }

      let i = new Date(date).getMonth(date);
      return monthName[i];
    },

    allowedDates(date) {
      var isAllowDate = false;

      //console.log(this.$moment(date).diff(this.$moment(), "days"));
      if (
        this.$moment(date).diff(this.$moment(), "days") < 0 &&
        this.$moment(date).diff(this.$moment("2020-03-25"), "days") >= 0
      ) {
        isAllowDate = true;
      }

      return isAllowDate;
    },

    /*
      async getOHLCV(){
        let sleep = (ms) => new Promise (resolve => setTimeout (resolve, ms));
  console.log('asdf');
        if (this.$store.state.exchange.has.fetchOHLCV) {
  
          let markets = await this.$store.state.exchange.load_markets();
  
  console.log('markets',markets);
            for (const symbol in markets) {
  //console.log(symbol);
  console.log(symbol,this.$store.state.exchange.rateLimit);
                await sleep (this.$store.state.exchange.rateLimit) // milliseconds
                console.log (await this.$store.state.exchange.fetchOHLCV (symbol, '1m',  undefined,  200, {})); // one minute
            }
        }
      }
  */



 
      
    initBacktest() {
        if(this.$nvl(this.botList,false)){

          
          this.selected_bot = this.botList[0].id;


          if(this.$localData('backtestSave') == 1){
              const backtestOption = this.$localData('backtestOption');
              //console.log('backtest' , backtestOption);
              
              this.selected_bot = backtestOption.selected_bot;
              this.date = backtestOption.date;
              this.date2 = backtestOption.date2;
              this.backtestSave = true;

          }



        }else{
          setTimeout(() => {
            this.initBacktest();
          }, 100);
        }
    }
  },

  async created() {

    this.initBacktest();



  },
};
</script>
  
  <style lang="scss" scoped>
.custom-picker {
  border-radius: 10px !important;

  .v-btn--active.green {
    background-color: #edffff !important;

    .v-btn__content {
      color: #00b1bb !important;
      font-weight: bold !important;
    }
  }

  .v-picker__title {
    display: none !important;
  }

  .v-date-picker-header {
    padding-top: 10px !important;
  }
}
.custom-picker > .v-picker__title {
  display: none;
}

.v-date-picker-table thead tr th {
  color: #1c1c1c !important;
  font-weight: 400 !important;

  &:nth-child(1) {
    color: #ff7451 !important;
  }

  &:nth-child(7) {
    color: #ff7451 !important;
  }
}

.v-date-picker-table tbody tr td {
  &:nth-child(1) {
    .v-btn--disabled {
      .v-btn__content {
        color: #ffcbbe;
      }
    }

    .v-btn__content {
      color: #ff7451;
    }
  }

  &:nth-child(7) {
    .v-btn--disabled {
      .v-btn__content {
        color: #ffcbbe;
      }
    }

    .v-btn__content {
      color: #ff7451;
    }
  }
}
</style>